<template>
    <div
        :id="note.id"
        class="note"
        :class="[
            { central: note.type == '2' },
            { pinned: note.pinned },
            {
                selected: (selected && selected.id == note.id) || (editing && editing.id == note.id) || (editing && editing == note.id)
            },
            isComment ? 'comment' : '',
            $mq
        ]"
        @click="comment()"
    >
        <div class="header">
            <div v-if="note.pinned" class="pinned-text">{{ $t('notes.highlight_message') }}</div>

            <template v-if="note.type == 2">
                <div class="employee" :data-date="note.created_date ? note.created_date.fromNow() : ''">
                    <div class="icon"></div>
                    {{ locationData.account_name }}
                </div>
            </template>

            <template v-else>
                <div class="employee" :data-date="note.created_date ? note.created_date.fromNow() : ''">
                    <div class="icon" :style="employees[note.employee] && employees[note.employee].avatar ? { backgroundImage: 'url(' + employees[note.employee].avatar + ')' } : {}"></div>
                    {{ employees[note.employee] ? employees[note.employee].name : $t('notes.user_unknown') }}
                    {{ employees[note.employee] ? employees[note.employee].surname : '' }}
                </div>
            </template>

            <div class="actions" :class="{ opened: openedMenu, disabled: !checkPermission('delete') && !checkPermission('pin') && !checkPermission('edit') && !checkPermission('comment') }" @click.stop="toggleActions()">
                <template v-if="openedMenu">
                    <div v-if="checkPermission('delete')" class="ico delete" @click="remove()"></div>
                    <div v-if="checkPermission('pin') && !isComment" class="ico pin" @click="pin()"></div>
                    <div v-if="checkPermission('edit')" class="ico edit" @click="edit()"></div>
                    <div v-if="!isComment" class="ico comment" @click="comment()"></div>
                    <div class="ico cancel" @click.stop="toggleActions()"></div>
                </template>
            </div>
        </div>

        <div class="content-user">
            <div v-if="editing.id != note.id" class="message" :class="{ noMessage: !note.message }" v-html="messageParse(note.message)"></div>
            <div class="editing" v-else>{{ $t('notes.editing') }}</div>

            <template v-if="['portrait'].includes($mq)">
                <div v-if="note.image" class="img-container" @click.stop="preview()">
                    <img :src="note.image" style="height: 300px;"/>
                    <!-- <cld-image class="image" :publicId="note.image" type="fetch" loading="lazy">
                        <cld-transformation height="300" radius="3" crop="fill" fetchFormat="auto" />
                    </cld-image> -->
                </div>
            </template>
            <template v-else>
                <div v-if="note.image" class="img-container" @click.stop="preview()">
                    <img :src="note.image" style="height: 100px;"/>
                    <!-- <cld-image class="image" :publicId="note.image" type="fetch" loading="lazy">
                        <cld-transformation height="100" radius="3" crop="fill" fetchFormat="auto" />
                    </cld-image> -->
                </div>
            </template>
        </div>

        <template v-if="!isComment">
            <template v-if="!showingComments">
                <div v-if="note.comments.count > 0" @click.stop="toggleComments()" class="show-responses">
                    {{ note.comments.count + ' ' + $tc('notes.comments', note.comments.count == 1 ? 1 : 2) }}
                </div>
            </template>

            <template v-else>
                <div class="showing-comments">
                    <div v-if="note.comments.count > 0" @click.stop="toggleComments()" class="hide-responses">
                        {{ $tc('notes.close_responses', note.comments.count == 1 ? 1 : 2) }}
                    </div>
                    <note v-for="item in comments" :key="item.id" :note="item" :isComment="true"></note>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    name: 'note',
    props: {
        note: { type: Object },
        isNew: { type: Boolean, default: false },
        isComment: { type: Boolean, default: false },
        isCentral: { type: Boolean, default: false }
    },
    data() {
        return {
            showingComments: true,
            openedMenu: false,
            cssImage: css_image_url
        }
    },
    computed: {
        account() {
            return this.$store.getters['loginUser/getLocalLocation'].account_name
        },
        employees() {
            return this.$store.getters['employee/getAllEmployees']
        },
        locationData() {
            return this.$store.getters['loginUser/getLocalLocation']
        },
        employee() {
            var employeeId = this.$store.getters['loginUser/getLocalEmployee']
            if (employeeId && this.employees) return this.employees[employeeId]
            return false
        },
        comments() {
            if (this.note.comments) {
                var allNotes = this.note.comments.data
                if (allNotes) {
                    return allNotes.sort((a, b) => (a.created_date > b.created_date ? 1 : b.created_date > a.created_date ? -1 : 0))
                }
            }
            return false
        },

        parent() {
            return this.isComment ? this.$parent.$parent : this.$parent
        },

        selected() {
            return this.$store.getters['notes/getSelected']
        },
        editing() {
            var id = this.$store.getters['notes/getEditing']
            if (typeof id != 'undefined') {
                return id
            }
            return false
        }
    },
    methods: {
        messageParse(msg) {
            if (typeof msg == 'string') {
                return msgParse(msg)
            }
            return msg
        },
        toggleActions() {
            this.openedMenu = !this.openedMenu
        },

        toggleComments() {
            this.showingComments = !this.showingComments
        },

        preview() {
            log('preview 1')
            if (this.note.image) {
                log('preview 2')
                this.$popup.media({ image: this.note.image, logbook: true })
            }
        },

        checkPermission(action) {
            var oEmployeeRol = this.$store.getters['employee/getRol'](this.employee.rol)
            var employeeNote = this.employees[this.note.employee]
            // Este if es para el caso de que se haya borrado el usuario que ha creado la nota en central y se quiere borrar
            if (this.isCentral) {
                log(employeeNote)
                if (employeeNote != undefined) {
                    employeeNote = this.$store.getters['employee/getRoles'](employeeNote.rol)
                } else {
                    employeeNote = 'central'
                }
            }

            if (typeof employeeNote != 'undefined') {
                var oNoteRol = this.$store.getters['employee/getRol'](employeeNote.rol)

                switch (action) {
                    case 'delete':
                        if (this.note.type == 2 && !this.isCentral) {
                            return false
                        } else if (this.note.type == 2 && this.isCentral && oEmployeeRol.level >= 6) {
                            return true
                        } else if (oEmployeeRol.level > oNoteRol.level) {
                            return true
                        } else if (this.employee.id == this.note.employee) {
                            return true
                        }
                        return false
                    case 'pin':
                        if (oEmployeeRol.level >= 3) {
                            return true
                        }
                        return false
                    case 'edit':
                        if (this.employee.id == this.note.employee && this.note.type != 2) {
                            return true
                        } else if (this.note.type == 2 && this.isCentral && oEmployeeRol.level >= 6) {
                            return true
                        }
                        return false
                    case 'comment':
                        if (this.note.type == 2 && this.isCentral && oEmployeeRol.level >= 6) {
                            return true
                        } else if (this.note.type == 2 && !this.isCentral) {
                            return false
                        } else if (oEmployeeRol.level >= 2) {
                            return true
                        }
                        return false
                    default:
                        return false
                }
            }
            return false
        },

        pin() {
            var self = this

            if (self.note.pinned) {
                self.$store.dispatch('notes/unpinLog', self.note.id).then(function () {
                    // TODO - Update store and do not refresh via API
                    self.refresh(true)
                })
            } else {
                self.$store.dispatch('notes/pinLog', self.note.id).then(function () {
                    // TODO - Update store and do not refresh via API
                    self.refresh(true)
                })
            }
        },

        edit() {
            // TODO - Call parent to edit this note and know if is comment
            this.$store.commit('notes/select', false)
            var self = this
            var value = this.editing && this.editing.id == this.note.id ? false : this.note.id
            // if parent id
            this.$store.commit('notes/edit', value)
        },

        comment() {
            // TODO - Call parent to reply this note
            this.$store.commit('notes/edit', false)
            var self = this
            var value = this.selected && this.selected.id == this.note.id ? false : this.note.id
            this.$store.commit('notes/select', value)
        },

        remove() {
            var self = this
            this.$popup.confirm({
                message: this.$t('popup.delete.title'),
                textSave: this.$t('popup.delete.text_save'),
                textCancel: this.$t('popup.delete.text_cancel'),
                callSave: function () {
                    self.$overlay.loading()
                    self.$store.dispatch('notes/deleteLog', self.note.id).then(function () {
                        self.refresh(true)
                    })
                }
            })
        },

        refresh(noScroll) {
            if (typeof noScroll == 'undefined') {
                noScroll = false
            } else {
                noScroll = true
            }
            this.parent.refreshNotes(noScroll)
        }
    },
    mounted() {
        this.showingComments = ['portrait'].includes(this.$mq) ? false : true
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$note-img-size: rem(60px);
$note-img-size-w: $note-img-size + rem(40px);
$comment-img-size: rem(50px);
$comment-img-size-w: $comment-img-size + rem(40px);
.pinned.landscape,
.pinned.desktop {
    .pinned-text {
        @include background($image: img('star_warning.svg'), $size: 20px, $position: 24px 4px !important);
    }
}

.note {
    @include interaction();
    @include border-radius(4px);
    display: block;
    width: 100%;
    padding: 5px 12px;
    margin: 5px auto 5px 0;
    overflow: visible;

    .show-responses {
        @include background($image: img('corner_right_down.svg'), $position: right 16px, $size: 13px);
        @include font-size(s);
        color: $main-s20;
        font-family: $conden-bold;
        width: fit-content;
        padding-right: 18px;
        padding-left: 46px;
        padding-top: 10px;
    }

    .header,
    .content-user {
        @include display-flex();
        width: 100%;
        margin: 0px 2px;

        .editing {
            @include background($image: img('edit_dark.svg'), $position: 1px 3px, $size: 12px);
            @include font-size(s);
            font-family: $text-bold;
            padding-left: 18px;
            color: $main-s50;
        }
    }

    .header {
        height: 45px;
        z-index: 1;
        .pinned-text {
            @include background($image: img('star_warning.svg'), $size: 20px, $position: 24px 4px);
        }

        .employee,
        .actions,
        .date {
            @include display-inline-flex();
            @include align-items();
            float: left;
        }

        .employee {
            @include font-size(sm);
            @include text-ellipsis();
            width: 100%;
            position: relative;
            color: #444;
            font-family: $text-bold;
            padding-left: 46px;
            min-height: 45px;
            overflow: visible;
            &:after {
                @include font-size(xs);
                content: attr(data-date);
                color: $neutro-s70;
                font-family: $text-light;
                padding-left: 8px;
                padding-top: 2px;
            }
            .icon {
                @include border-radius(40px);
                @include background($image: img('empty_avatar.svg'));
                margin-right: 6px;
                position: absolute;
                width: 40px;
                height: 40px;
                top: 4px;
                left: 0px;
            }
        }

        .actions {
            @include border-radius(6px);
            @include background($image: img('more_vert_aaaaaa.svg'), $position: right center, $size: 20px);
            @include justify-content(flex-end);
            width: 100px;
            min-height: 40px;
            position: relative;

            .ico {
                @include icoClass();
                background-size: 18px;

                &.pin {
                    background-image: img('star_main.svg');
                    background-size: 22px;
                }
                &.edit {
                    background-image: img('edit_dark.svg');
                }
                &.delete {
                    background-image: img('delete_alert.svg');
                }
                &.save {
                    background-image: img('edit_dark.svg');
                    background-size: 19px;
                }
                &.cancel {
                    background-image: img('more_vert_aaaaaa.svg');
                    background-size: 20px;
                    width: 30px;
                }
                &.comment {
                    background-image: img('chatbox_neutro_s70.svg');
                    background-size: 22px;
                }
            }

            &.opened {
                background-color: $neutro-light;
                width: auto;
                background-image: unset;
                box-shadow: 0px 0px 4px rgba(88, 87, 87, 0.08);
                margin-right: -5px;
                z-index: $z-menu-top + 1;
            }

            &.disabled {
                opacity: 0.3;
            }
        }
    }

    .content-user {
        @include flex-wrap(wrap);
        margin: 0px 2px;
        padding: 0;
        padding-left: 41px;
        width: 100%;
        height: auto;
        .img-container {
            .image {
                max-height: 150px;
                height: 100%;
                margin-top: 10px;
                cursor: pointer;
            }
        }

        .message {
            display: inline-block;
            @include font-size(sm);
            color: $neutro-s90;
            font-family: $text;
            width: calc(100% - #{$note-img-size});
            padding: 4px;
            padding-top: 0;
            height: 100%;
            word-break: break-word;
            // margin-top: -12px;
            &.noMessage {
                width: 0px;
            }
            a {
                @include font-size(inherit);
                line-height: inherit;
                color: $main-s30;
                text-decoration: underline;
            }
            b,
            li,
            ul,
            strong {
                @include font-size(inherit);
                line-height: inherit;
            }
            ul {
                padding-left: 30px;
                list-style: disc;
            }
        }
    }

    .showing-comments {
        width: auto;
        padding-left: 40px;
        padding-right: 0px;
        padding-top: 10px;
        position: relative;
        // transition-duration: 0.2s;

        .hide-responses {
            @include background($image: img('corner_right_up.svg'), $position: right 4px, $size: 13px);
            @include font-size(s);
            color: $main-s20;
            font-family: $conden-bold;
            width: fit-content;
            padding-right: 18px;
            padding-left: 16px;
            padding-bottom: 12px;
            cursor: pointer;
        }
    }

    &:hover {
        background-color: hover($default-bg-color);
    }
    &.comment {
        padding: 5px 0;
    }
    &.pinned {
        background-color: $warning-t80;

        > .header {
            position: relative;
            height: auto;
            padding-top: 30px;

            .employee {
                padding-bottom: 5px;
                height: 47px;
            }

            .pinned-text {
                @include background($image: img('star_warning.svg'), $size: 20px, $position: 24px 8px);
                @include font-size(sm);
                color: $warning-s10;
                font-family: $conden-bold;
                display: block;
                padding: 6px 0 6px 48px;
                width: 100%;
                height: 30px;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        &:hover,
        .comment:hover {
            background-color: hover($warning-t80);
        }
    }
    &.central {
        background-color: #fff;

        > .header {
            .employee .icon {
                @include background($color: $main, $image: img('megaphone_ffffff.svg'), $size: 30px);
            }
            .employee-info {
                @include font-size(m);
            }
        }

        &:hover,
        .comment:hover {
            background-color: hover(#fff);
        }
    }

    &.selected {
        box-shadow: 0px 0px 4px rgba(88, 87, 87, 0.08);
        background-color: $main-t80;

        &:hover,
        &.comment:hover,
        .comment:hover {
            background-color: hover($main-t80);
        }
    }
}
</style>

<!-- PORTRAIT STYLES -->
<style lang="scss" scoped>
.portrait .note {
    &.pinned {
        .content-user {
            justify-content: center;
        }
    }
}
.note:not(.pinned):not(.central):not(.selected).portrait {
    overflow: hidden;
    padding: 5px;
    // background-color: hover($default-bg-color);

    .header {
        top: 0px;
        .pinned-text {
            @include background($image: img('star_warning.svg'), $size: 20px, $position: 24px 8px);
            padding: 6px 0 6px 20px;
            @include font-size(xs);
            background-position: 0;
        }

        .employee {
            @include font-size(s);
            padding-left: 35px;
            margin-top: 4px;

            &:after {
                @include font-size(0.6rem);
                position: absolute;
                top: 29px;
                padding: 0;
            }

            .icon {
                width: 30px;
                height: 30px;
                background-size: 30px;
                top: 11px;
            }
        }
    }

    .content-user {
        padding-left: 0;
        .message {
            @include font-size(s);
            line-height: 18px;
            margin-top: 5px;
            width: 100%;
        }

        .img-container {
            text-align: center;
            margin: 0 auto;

            .image {
                max-height: 250px;
                height: 100%;
            }
        }
    }

    .showing-comments {
        padding-left: 30px;

        .hide-responses {
            @include font-size(xs);
            float: right;
        }
    }
    .show-responses {
        @include font-size(xs);
        float: right;
    }
    &:hover {
        background-color: hover($default-bg-color);
    }
}

.portrait {
    .note {
        padding: 0.9em;
        .header {
            height: max-content;
            .employee {
                padding-left: 50px;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-top: 8px;
                padding-top: 1px;
                &:after {
                    padding-left: 0px;
                }

                .icon {
                    margin-right: 20px;
                }
            }
        }

        .content-user {
            padding: 0;
        }
        .message {
            margin-top: 0.8rem !important;
            width: 100% !important;
            padding: 0px !important;
        }
    }
}
</style>

<style lang="scss">
.note{
    .content-user{
        .message{
            u{
                text-decoration: underline;
            }

            ul{
                list-style:circle;
                padding-left: 30px;
            }

            ol{
                list-style:decimal;
                padding-left: 30px;
            }
        }
    }
}
</style>
